import React from "react";
import Layout from "../../components/Layout";
import Container from "../../components/Container";

export default () => (
  <Layout>
    <section className="section">
      <Container>
        <div className="content">
          <h1>Thank you!</h1>
          <p>We will be in touch as soon as possible.</p>
        </div>
      </Container>
    </section>
  </Layout>
);
